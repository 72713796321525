//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitiesListTable from '@/modules/cities/components/cities-list-table.vue';
import CitiesFormModal from '@/modules/cities/components/cities-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { CitiesPermissions } from '@/modules/cities/cities-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-cities-list-page',

  components: {
    [CitiesListTable.name]: CitiesListTable,
    [CitiesFormModal.name]: CitiesFormModal,
  },
  data() {
    return {
      modalVisible: false,
      dialogVisible: false,
      unsubscribe:undefined
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new CitiesPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.cities') 
    },
  },

  // async created() {
  //   await firebase.firestore().collection('city').onSnapshot(async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFilterInBackground();
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },
  async mounted() {
    await this.doFetchCities()
    this.unsubscribe = firebase.firestore().collection('city').onSnapshot(() => {
      this.doFetchCities()
    })
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  methods: {
    ...mapActions({
      doFetchCities: 'citiesRegions/list/doFetchCities',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { citiesType: 'branch' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = { citiesType: 'branch' }
      return this.doFetchInBackground({
        filter,
      });
    },

  },

};
