//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CitiesModel } from '@/modules/cities/cities-model';
import i18n  from '@/vueI18n';
// import firebase from 'firebase/app'
// import 'firebase/firestore'

const { fields } = CitiesModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.regions,
  fields.countryId
]);

export default {
  name: 'app-cities-form-modal',

  props: ['id', 'visible'],

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.name = {
        en: undefined,
        ar: undefined,
      }
    }

    this.doFetchCountries()
  },

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      successDialog: false,
      countryOptions: [],
    };
  },

  computed: {
    ...mapGetters({
      countries: 'country/list/rows',
      loadingCountries: 'country/list/loading',

      record: 'citiesRegions/form/record',
      findLoading: 'citiesRegions/form/findLoading',
      saveLoading: 'citiesRegions/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    language(){
      return i18n.locale
    },
    countriesList() {
      return this.countries.map(item => {
        return {
          label: item.name[this.language],
          value: item.id, 
        }
      })
    },
  },
  
  methods: {
    ...mapActions({
      doFind: 'citiesRegions/form/doFindCity',
      doNew: 'citiesRegions/form/doNew',
      doUpdate: 'citiesRegions/form/doUpdateCity',
      doCreate: 'citiesRegions/form/doCreate',
      doFetchCities: 'citiesRegions/list/doFetchCities',
      doFetchCountries: 'country/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    OnClear() {
      this.model.sellerParts = []
    },
    filterGovernorateFn (val, update) {
      if (val === '') {
        update(() => {
          this.countryOptions = this.countriesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.countriesOptions = this.countriesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
        });
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate(values);
        await this.doFetchCities()
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
